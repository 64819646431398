import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable, Subject, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private idle$: Observable<Event>;
  private timerSubscription: Subscription;
  private timeOutMilliSeconds: number;
  private idleSubscription: Subscription;
  public expiredSubject: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  public startWatching(timeOutSeconds: number): Observable<boolean> {
    this.idle$ = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'click'),
      fromEvent(document, 'mousedown'),
      fromEvent(document, 'keypress'),
      fromEvent(document, 'DOMMouseScroll'),
      fromEvent(document, 'mousewheel'),
      fromEvent(document, 'touchmove'),
      fromEvent(document, 'MSPointerMove'),
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'resize')
    );

    this.timeOutMilliSeconds = timeOutSeconds * 1000;

    this.idleSubscription = this.idle$.subscribe(res => {
      this.resetTimer();
    });

    this.startTimer();

    return this.expiredSubject.asObservable();
  }

  private startTimer() {
    this.timerSubscription = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds).subscribe(
      res => {
        this.expiredSubject.next(true);
      }
    );
  }

  public resetTimer() {
    this.timerSubscription.unsubscribe();
    this.startTimer();
  }

  public stopTimer() {
    this.timerSubscription.unsubscribe();
    this.idleSubscription.unsubscribe();
  }
}
