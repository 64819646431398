export class Dictionary<T> {
  _keys: string[] = [];
  _values: T[] = [];

  constructor(init?: { key: string; value: T }[]) {
    if (init)
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let x = 0; x < init.length; x++) {
        this[init[x].key] = init[x].value;
        this._keys.push(init[x].key);
        this._values.push(init[x].value);
      }
  }

  addOrUpdate(key: string, value: T) {
    if (this[key]) {
      // update
      const i = this._keys.indexOf(key);
      this._values[i] = value;
    } else {
      // add
      this._keys.push(key);
      this._values.push(value);
    }

    this[key] = value;
  }

  remove(key: string) {
    const index = this._keys.indexOf(key, 0);
    if (index > -1) {
      this._keys.splice(index, 1);
      this._values.splice(index, 1);

      delete this[key];
    }
  }

  keys(): string[] {
    return this._keys;
  }

  values(): T[] {
    return this._values;
  }

  containsKey(key: string) {
    if (typeof this[key] === 'undefined') {
      return false;
    }

    return true;
  }
}

export class DictionaryPlus<K, V> {
  _keys: K[] = [];
  _values: V[] = [];

  constructor(init?: { key: K; value: V }[]) {
    if (init)
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let x = 0; x < init.length; x++) {
        this[init[x].key.toString()] = init[x].value;
        this._keys.push(init[x].key);
        this._values.push(init[x].value);
      }
  }

  addOrUpdate(key: K, value: V) {
    if (this[key.toString()]) {
      // update
      const i = this._keys.indexOf(key);
      this._values[i] = value;
    } else {
      // add
      this._keys.push(key);
      this._values.push(value);
    }

    this[key.toString()] = value;
  }

  remove(key: K) {
    const index = this._keys.indexOf(key, 0);
    if (index > -1) {
      this._keys.splice(index, 1);
      this._values.splice(index, 1);

      delete this[key.toString()];
    }
  }

  keys(): K[] {
    return this._keys;
  }

  values(): V[] {
    return this._values;
  }

  containsKey(key: K) {
    if (typeof this[key.toString()] === 'undefined') {
      return false;
    }

    return true;
  }
}

export class Address {
  Id: string;
  Name: string;
  Street: string;
  Street2: string;
  City: string;
  State: string;
  Country: string;
  ZipCode: string;
  Phone: string;
}

export class GraphicsModel {
  public x: number;
  public y: number;
  public scale: number;
  public activeInteraction?: boolean;
  public defaultScale?: number;
  public itemWidth?: number;
  public itemHeight?: number;
  public containerWidth?: number;
  public containerHeight?: number;
  public isReversed?: boolean;
  public offsetX?: number;
  public offsetY?: number;

  constructor(
    gm: GraphicsModel = {
      x: 0,
      y: 0,
      scale: 1,
      isReversed: false,
      defaultScale: 1,
      itemWidth: 0,
      itemHeight: 0,
      containerWidth: 0,
      containerHeight: 0,
      activeInteraction: false,
      offsetX: 0,
      offsetY: 0
    }
  ) {
    this.x = gm.x;
    this.y = gm.y;
    this.scale = gm.scale;
    this.activeInteraction = gm.activeInteraction;
    this.defaultScale = gm.defaultScale;
    this.itemWidth = gm.itemWidth;
    this.itemHeight = gm.itemHeight;
    this.containerWidth = gm.containerWidth;
    this.containerHeight = gm.containerHeight;
    this.isReversed = gm.isReversed;
    this.offsetX = gm.offsetX;
    this.offsetY = gm.offsetY;
  }
}

export class SelectionList<T> {
  onItemToggle: (item: T, list: SelectionList<T>, wasRemoved: boolean) => void;
  array = new Array<T>();

  get length(): number {
    return this.array.length;
  }

  constructor(itemToggleHandler?: (item: T, list: SelectionList<T>, wasRemoved: boolean) => void) {
    this.onItemToggle = itemToggleHandler;
  }

  includes(obj: T): boolean {
    return this.array.indexOf(obj) > -1;
  }

  forEach(cb: (arg: T) => void) {
    this.array.forEach(cb);
  }

  reset() {
    this.array.splice(0, this.array.length);
  }

  toggleItem(item: T, useOnItemToggle = true) {
    let removed = false;
    const index = this.array.indexOf(item);
    if (index > -1) {
      removed = true;
      this.array.splice(index, 1);
    } else {
      this.array.push(item);
    }

    if (this.onItemToggle && useOnItemToggle) this.onItemToggle(item, this, removed);
  }

  addItem(item: T, doToggle = true) {
    // will only add if not already there (no dupes)
    const index = this.array.indexOf(item);
    if (index === -1) {
      this.array.push(item);

      if (this.onItemToggle && doToggle) this.onItemToggle(item, this, false);
    }
  }

  removeItem(item: T) {
    const index = this.array.indexOf(item);
    if (index > -1) {
      this.array.splice(index, 1);

      if (this.onItemToggle) this.onItemToggle(item, this, true);
    }
  }
}

export class FileMetadata {
  DocumentId: string;
  OriginalFileName: string;

  constructor(documentId?: string, originalFileName?: string) {
    this.DocumentId = documentId;
    this.OriginalFileName = originalFileName;
  }
}

export class SelectOption {
  Id: number;
  Name: string;
}

export class SelectOptionGroup {
  Name: string;
  Options: SelectOption[];
}
