import { Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Manager } from 'hammerjs';

import { GraphicsModel } from './data.structures';
import { Lookup } from './lookup';

// Use this directive to handle pan and pinch interactions
// This ONLY registers Hammer events and emits data upward. Does NOT update any element styles. See ml-transformer for that.
@Directive({
  selector: '[mlInteract]'
})
export class MlInteractDirective implements OnInit {
  @Output() interaction: EventEmitter<any> = new EventEmitter<GraphicsModel>();
  // This GM should never be modified directly by this directive.
  // Its only purpose is to sync starting event data when other page features will be modifying GM
  @Input('mlInteract') readOnlyGm: GraphicsModel;

  private element: HTMLElement;
  private hammerManager: HammerManager;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.element = this.elementRef.nativeElement;

    this.hammerManager = new Manager(this.element);

    this.createRecognizers(this.hammerManager);

    this.subscribeToEvents(this.hammerManager);
  }

  // creates pan and pinch recognizers with settings different than Hammer defaults
  createRecognizers(hm: HammerManager) {
    const pan = new Hammer.Pan({ threshold: 0, pointers: 0, direction: Hammer.DIRECTION_ALL });
    const pinch = new Hammer.Pinch({ enable: true });
    const pressdown = new Hammer.Press({ event: 'pressdown', time: 0 });

    // Important: allows gestures to fire simultaneously (only have to call recognizeWith() 1-way)
    pan.recognizeWith(pressdown);
    pinch.recognizeWith(pan);

    hm.add([pressdown, pan, pinch]);
  }

  subscribeToEvents(hm: HammerManager) {
    let newX = 0;
    let newY = 0;
    let newScale = 1;

    let startX = newX;
    let startY = newY;
    let startScale = newScale;

    // START
    // note that "panstart" does NOT fire on pressdown but on first move
    // We need to set the startScale on panstart and pinchstart otherwise when multiple events are firing, startScale doesn't set correctly.
    // Also, pressdown does not always fire if you immediately start panning or pinching.
    this.hammerManager.on('pressdown panstart pinchstart', evt => {
      if (this.readOnlyGm) {
        startX = this.readOnlyGm.x;
        startY = this.readOnlyGm.y;
        startScale = this.readOnlyGm.scale;

        // in case the end event fires without the move firing the new need to match the readOnlyGm values also
        newScale = startScale;
        newX = startX;
        newY = startY;
      } else {
        // if no readOnlyGm was passed then carry-over values from last event interactions as start values
        startX = newX;
        startY = newY;
        startScale = newScale;
      }
    });

    // MOVE
    this.hammerManager.on('panmove pinchmove', evt => {
      // keep scale within bounds
      newScale = startScale * evt.scale;
      newScale = Math.max(Lookup.Graphics.MinScale, Math.min(Lookup.Graphics.MaxScale, newScale));

      // only allow pan after scaling in
      if (newScale !== 1) {
        newX = startX + evt.deltaX;
        newY = startY + evt.deltaY;

        // bound x and y so that half of scaled content is always visible
        const maxX = Math.ceil(((newScale - 1) * this.element.clientWidth) / 2);
        const minX = -1 * maxX;
        const maxY = Math.ceil(((newScale - 1) * this.element.clientHeight) / 2);
        const minY = -1 * maxY;

        newX = Math.max(minX, Math.min(maxX, newX));
        newY = Math.max(minY, Math.min(maxY, newY));
      }

      this.interaction.emit(
        new GraphicsModel({ x: newX, y: newY, scale: newScale, activeInteraction: true })
      );
    });

    // END
    // this fires on pointerup but only if panning/pinching has occurred
    this.hammerManager.on('panend pinchend', evt => {
      // We really only want to fire the end event if we're letting go of all inputs
      if (evt.isFinal) {
        // on end emit final GM with activeInteraction = false
        this.interaction.emit(
          new GraphicsModel({ x: newX, y: newY, scale: newScale, activeInteraction: false })
        );
      }
    });
  }
}
