import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

import { IMedia, MediaType } from '@ml/common';
import { Lookup } from '../shared/lookup';
import { ToastService } from '../shared/toast/toast.service';
import { MediaSQL } from './media';

@Injectable()
export class MediaService {
  stageApiBase = window.location.protocol + '//' + Lookup.Argo.StageHost;

  constructor(private http: HttpClient, private toast: ToastService) {}

  getByFloorPlanId(floorplanId: number, fromStage = false): Observable<IMedia[]> {
    const baseHost = fromStage ? this.stageApiBase : '';
    return this.http.get<IMedia[]>(`${baseHost}/api/mediav2/floorplan/${floorplanId}`);
  }

  getByCommunityExtended(
    communityId: number,
    mediaType: MediaType = null,
    fromStage = false
  ): Observable<MediaSQL[]> {
    const baseHost = fromStage ? this.stageApiBase : '';
    const qParam = mediaType ? `?mediaType=${mediaType}` : '';
    return this.http.get<MediaSQL[]>(
      `${baseHost}/api/mediav2/communityextended/${communityId}${qParam}`
    );
  }

  create(media: MediaSQL, file: File, fromStage = false) {
    const form = new FormData();
    form.append('mediaDTO', JSON.stringify(media));
    form.append(file.name, file, file.name);
    const base = fromStage ? this.stageApiBase : '';
    return this.http.post<MediaSQL>(`${base}/api/mediav2/file`, form).toPromise();
  }

  delete(id: number, fromStage = false) {
    const base = fromStage ? this.stageApiBase : '';
    return this.http.delete<void>(`${base}/api/mediav2/${id}`).toPromise();
  }

  createEntries(media: MediaSQL[], files: File[]): Observable<MediaSQL[]> {
    const url = `${this.stageApiBase}/api/mediav2/files`;

    const formData = new FormData();
    formData.append('mediaDTO', JSON.stringify(media));
    files.forEach(file => {
      formData.append(file.name, file, file.name);
    });

    return this.http.post<MediaSQL[]>(url, formData);
  }

  createOrUpdateEntries(media: IMedia[], files: File[]): Observable<IMedia[]> {
    const url = `${this.stageApiBase}/api/mediav2/files`;

    const formData = new FormData();
    formData.append('mediaDTO', JSON.stringify(media));
    files.forEach(file => {
      formData.append(file.name, file, file.name);
    });

    return this.http.put<IMedia[]>(url, formData);
  }

  createFloorMediaByNetworkLocation(location: string, floorId: number) {
    const url = `${this.stageApiBase}/api/mediav2/createfrompath/${floorId}`;
    console.log(location);

    return firstValueFrom(this.http.post<IMedia[]>(url, { path: location }))
      .then(res => {
        return res;
        // console.log(res);
      })
      .catch(err => {
        let msg = '';
        if (err.error?.Message) {
          msg = err.error.Message;
        } else {
          msg = err.message;
        }
        console.log(err);
        this.toast.showError('Error creating media', msg);
      });
  }
}
