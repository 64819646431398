export enum ContentEditorProducts {
  UniversalThemeAndContent,
  MyScp,
  HomeConfigurator,
  RIFP,
  PanoViewer
}

export enum SvgContentTypes {
  Floor = 'FLOOR',
  SiteMap = 'SITE_MAP'
}
